.index-page {

  .first-block {
    color:#FFF;
    position:relative;
    min-height: 340px;

    .bg-img {
      opacity: 0.7;
      width:100%;
      max-width: 100%;

      &.large {
        display: block;
      }

      &.small {
        display: none;
      }

      @media (max-width: 1200px) {
        &.large {
          display: none;
        }

        &.small {
          display: block;
        }
      }

      @media (max-width: 500px) {
        position:absolute;
        height:100%;
        width:100%;
        top:0;
        left:0;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    .inner {
      position:absolute;
      top:40%;
      width:100%;

      h1 {
        font: 400 66px/1.2 "Intro-Bold", sans-serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 20px;
      }

      p {
        font: 300 26px/1.2 "Roboto", sans-serif;
        max-width: 725px;
        color: #FFF;
        margin: 0;
        text-shadow: 0 0 14px #020209;
      }

      @media (max-width: 1100px) {
        h1 {
          font-size: 58px;
        }
        p {
          font-size: 23px;
        }
      }

      @media (max-width: 800px) {
        h1 {
          font-size: 54px;
          margin-bottom: 5px;
        }
        p {
          font-size: 24px;
        }
      }

      @media (max-width: 740px) {
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 16px;
        }
      }

      @media (max-width: 500px) {
        h1 {
          font-size: 26px;
          margin-bottom: 12px;
          letter-spacing: normal;
        }
        p {
          font-size: 18px;
        }
      }
    }
    margin-bottom:10px;
  }

  .preference-block {
    overflow: hidden;
    background: #020209;
    padding: 46px 0 20px;
  }

  .page-video-box {
    margin: 0 auto;
    width: 100%;
    max-width: 740px;
    position: relative;

    > .title {
      font: 30px/1.2 "Roboto", sans-serif;
      color: #8C9ABE;
      margin-bottom: 20px;
      position: relative;
      z-index: 2;
      letter-spacing: 1px;
    }

    .video-box {
      z-index: 2;
    }
  }

  .video-box {
    position: relative;
    width: 100%;
    font-size: 0;
    font-weight: normal;
    text-align: center;

    > {
      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .video-cover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        > img {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
          background-size: cover;
        }
      }
    }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 0;
      padding-top: 56%;
    }
  }

  .video-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .btn-play {
    width: 100px;
    font-size: 0;
    font-weight: normal;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 20%;
    cursor: pointer;
    background: rgb(219, 4, 115);
    background: -moz-linear-gradient(330deg, rgb(219, 4, 115) 15%, rgb(231, 2, 10) 85%);
    background: -webkit-linear-gradient(330deg, rgb(219, 4, 115) 15%, rgb(231, 2, 10) 85%);
    background: -o-linear-gradient(330deg, rgb(219, 4, 115) 15%, rgb(231, 2, 10) 85%);
    background: -ms-linear-gradient(330deg, rgb(219, 4, 115) 15%, rgb(231, 2, 10) 85%);
    background: linear-gradient(60deg, rgb(219, 4, 115) 15%, rgb(231, 2, 10) 85%);

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 0;
      padding-top: 70%;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      width: 50%;
      height: 50%;
      background: url(../images/zilant/btn_play.png) no-repeat 50% 50%;
      background-size: contain;
      max-width: 27px;
      max-height: 29px;
    }
  }

  .preference-box {
    margin: 100px auto 0;
    width: 100%;
    max-width: 1176px;
    position: relative;
  }

  .preference-unit {
    width: 100%;
    position: relative;
    display: flex;
    display: -ms-flexbox;
    flex-direction: row;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;

    .text-num {
      position: relative;
      z-index: 2;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin-top: -30px;

      .num {
        color: #FFF;
        font: 400 60px/60px "Intro-Bold", sans-serif;
        white-space: nowrap;
        position: relative;
        z-index: 2;
        padding-top: 42px;
        min-width: 40px;
      }

      .icon {
        display: block;
        width: 78px;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 100%;
        margin-left: -6px;
        pointer-events: none;
        -webkit-pointer-events: none;

        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          position: relative;
          z-index: 1;
        }

        .img_icon {
          top: 10px;
          left: -10px;

          &.t0 {
            top: 0;
          }
        }

        img {
          &.light {
            position: absolute;
            z-index: 0;
            width: auto;
            height: auto;
            max-width: none;
          }

          &.light_1 {
            width: 159%;
            top: 0;
            left: 0;
            -webkit-transform: translateX(-22%) translateY(-26%);
            transform: translateX(-22%) translateY(-26%);
          }

          &.light_2 {
            width: 177%;
            top: 0;
            left: 0;
            -webkit-transform: translateX(-14%) translateY(-20%);
            transform: translateX(-14%) translateY(-20%);
          }

          &.light_3 {
            width: 183%;
            top: 0;
            left: 0;
            -webkit-transform: translateX(-25%) translateY(-38%);
            transform: translateX(-25%) translateY(-38%);
          }

          &.light_4 {
            width: 184%;
            top: 0;
            left: 0;
            -webkit-transform: translateX(-20%) translateY(-20%);
            transform: translateX(-20%) translateY(-20%);
          }
        }
      }
    }

    .text {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      font: 300 24px/1.2 "Roboto", sans-serif;
      text-align: left;
      color: #B5C7F4;
      position: relative;
      z-index: 2;
      padding-top: 38px;
      padding-left: 4px;
    }
  }

  .space_fog {
    width: auto;
    height: auto;
    -webkit-pointer-events: none;
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .pc-characteristics-wrap {
    display: flex;
    display: -ms-flexbox;
    flex-direction: row;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: stretch;
    align-items: stretch;
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    margin-top: 47px;
    position: relative;
  }

  .space_fog_2 {
    position: absolute;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    display: block;
    -webkit-transform: translateX(-50%) translateY(-65%);
    transform: translateX(-50%) translateY(-65%);
    -webkit-pointer-events: none;
    pointer-events: none;
  }

  .pc-characteristics-cover {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 649px;
    margin-left: -102px;
    position: relative;

    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }

  .pc-characteristics-content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-top: 110px;
    padding-left: 59px;
    position: relative;
  }

  .dash-title {
    font: 400 48px/1.2 "Intro-Bold", sans-serif;
    color: #FFF;
    text-transform: uppercase;
    position: relative;
    padding-left: 100px;
    margin-left: -100px;
    text-align: left;

    &:before {
      content: '';
      display: block;
      height: 4px;
      width: 62px;
      -webkit-pointer-events: none;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 27px;
      background: #E60500;
    }
  }

  .pc-characteristics-content .dash-title {
    margin-bottom: 34px;
  }

  .ch-unit {
    display: flex;
    display: -ms-flexbox;
    flex-direction: row;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;

    > {
      .icon {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 48px;
        margin-top: 2px;
      }

      .content {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        text-align: left;
        min-height: 74px;

        .category {
          color: #6C7A9B;
          font: 300 20px/1.2 "Roboto", sans-serif;
        }

        .name {
          color: #B5C7F4;
          font: 500 20px/1.2 "Roboto", sans-serif;
        }

        p {
          margin: 0;
        }
      }
    }

    &:nth-child(n+2) {
      margin-top: 24px;
    }
  }

  .zones-block {
    background: #020209;
    padding-bottom: 40px;
    padding-top: 80px;
  }

  .zone-item {
    position: relative;
    min-height: 648px;
    flex: 0 0 auto;
    padding: 217px 40px 120px !important;
    font-size: 0;
    font-weight: normal;

    > img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .zone-item-text {
    position: relative;
    z-index: 2;
    color: #FFF;
    text-align: left;
    width: 100%;
    max-width: 456px;
    padding-left: 126px;
    display: inline-block;
    vertical-align: middle;

    > {
      .title {
        font: 400 48px/1.2 "Intro-Bold", sans-serif;
        text-transform: uppercase;
        padding-left: 110px;
        margin-left: -110px;
        letter-spacing: 1px;
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 27px;
          left: 0;
          width: 87px;
          height: 5px;
          -webkit-pointer-events: none;
          pointer-events: none;
          background: #E60500;
        }
      }

      ul {
        display: block;
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
          display: block;
          font: 300 22px/1.2 "Roboto", sans-serif;
          letter-spacing: .7px;

          &:nth-child(n+2) {
            margin-top: 22px;
          }
        }

        &:nth-child(n+2) {
          margin-top: 20px;
        }

        > li:nth-child(n+2) {
          margin-top: 16px;
        }
      }
    }
  }

  .zones-block .zones-box {
    margin: -180px 0;
  }

  .club-block {
    background: #020203;
    padding: 80px 0 100px;
    overflow: visible;
  }

  .club {
    padding-top: 20px;
  }

  .club-block:before {
    height: 288px;
    top: auto;
    bottom: -111px;
    z-index: 2;
  }

  .club-table-outer {
    position: relative;
    z-index: 10;
  }

  .square-decor {
    display: block;
    -webkit-pointer-events: none;
    pointer-events: none;
    width: 270px;
    height: 262px;
    position: absolute;
    top: -37px;
    left: -37px;
    background: url(../images/zilant/bg_square.png) no-repeat 0 0;
    background-size: contain;
    opacity: .7;
  }

  .club-table-wrap {
    background: #0F0D18;
    border-radius: 5px;
    padding: 0 40px 0;
    position: relative;
    z-index: 10;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      width: 80%;
      height: 1px;
      box-sizing: border-box;
      left: 0;
      right: 0;
      margin: auto;
      background: rgb(0, 35, 90);
      background: -moz-linear-gradient(0deg, rgba(0, 35, 90, 0) 0%, rgba(0, 35, 90, 1) 50%, rgba(0, 35, 90, 0) 100%);
      background: -webkit-linear-gradient(0deg, rgba(0, 35, 90, 0) 0%, rgba(0, 35, 90, 1) 50%, rgba(0, 35, 90, 0) 100%);
      background: -o-linear-gradient(0deg, rgba(0, 35, 90, 0) 0%, rgba(0, 35, 90, 1) 50%, rgba(0, 35, 90, 0) 100%);
      background: -ms-linear-gradient(0deg, rgba(0, 35, 90, 0) 0%, rgba(0, 35, 90, 1) 50%, rgba(0, 35, 90, 0) 100%);
      background: linear-gradient(90deg, rgba(0, 35, 90, 0) 0%, rgba(0, 35, 90, 1) 50%, rgba(0, 35, 90, 0) 100%);
      z-index: 2;
    }
  }

  .packages-wrap {
    &:before, &:after {
      width: 40%;
    }
  }

  .club-table-wrap {
    &:before {
      top: 0;
      bottom: auto;
    }

    &:after {
      bottom: 0;
      top: auto;
    }
  }

  .table-blue-lights-1 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    overflow: hidden;
    width: 260px;
    height: 250px;
    -webkit-pointer-evetns: none;
    poonter-events: none;

    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 1px;
      box-sizing: border-box;
      top: 0;
      left: 0;
      position: absolute;
      border-radius: 50%;
      box-shadow: 0 0 100px 40px rgba(0, 75, 235, 1);
    }
  }

  .table-blue-lights-2 {
    display: block;
    position: absolute;
    overflow: hidden;
    width: 500px;
    height: 200px;
    -webkit-pointer-evetns: none;
    poonter-events: none;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 1px;
      box-sizing: border-box;
      top: 237px;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
      border-radius: 50%;
      box-shadow: 0 0 150px 54px rgb(0, 75, 235);
    }
  }

  .packages-wrap .table-blue-lights-2 {
    bottom: auto;
    top: 0;

    &:before {
      top: -60px;
    }
  }

  .space_fog_3 {
    pointer-events: none;
    -webkit-pointer-events: none;
    width: auto;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translateX(-33%) translateY(-36%);
    transform: translateX(-33%) translateY(-36%);
    z-index: 0;
  }

  .club-table {
    border-collapse: collapse;
    color: #FFF;
    width: 100%;
    z-index: 2;
    position: relative;

    th:nth-child(n+2) {
      border-left: 1px solid rgba(57, 55, 73, 0.5);
    }

    td {
      &:nth-child(n+2) {
        border-left: 1px solid rgba(57, 55, 73, 0.5);
      }

      vertical-align: top;
      text-align: center;
    }

    th {
      vertical-align: top;
      text-align: center;
    }

    td:first-child, th:first-child {
      text-align: left;
    }
  }

  .club-title {
    font: 400 26px/1.2 "Intro-Bold", sans-serif;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding:10px;

    &:after {
      content: '';
      display: block;
      width: 100%;
      box-sizing: border-box;
      height: 4px;
      background: #A70102;
      margin-top: 20px;
      max-width: 98px;
    }
  }

  .club-table {
    .td-title {
      font: 300 18px/1.2 "Roboto", sans-serif;
      color: #B5C7F4;
      letter-spacing: .5px;
    }

    .td-desc {
      font: 300 14px/1.2 "Roboto", sans-serif;
      color: rgba(181, 199, 244, 0.5);
      letter-spacing: .5px;

      &.red {
        color: #E60500;
      }
    }

    .btn-user {
      min-width: 140px;
      border-color: #E60500;

      &:hover {
        background: #E60500;
      }
    }

    p {
      margin: 0;
    }

    thead th {
      padding-top: 56px;
    }

    tbody tr {
      &:nth-child(n+2) td {
        padding-top: 15px;
      }

      &:first-child td {
        padding-top: 30px;
      }

      &:last-child td {
        padding-bottom: 60px;
        padding-top: 38px;
      }
    }

    td, th {
      padding-left: 20px;
      padding-right: 20px;
    }

    td:first-child, th:first-child {
      padding-left: 0;
    }

    td:last-child, th:last-child {
      padding-right: 0;
    }
  }

  .card-head {
    width: 100%;
  }

  .card-logo {
    margin: -82px auto 40px;
    max-width: 242px;
    width: 100%;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      box-shadow: 0 0 100px 38px #F80000;
      z-index: 0;
    }

    img {
      margin: 0 auto;
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      border-radius: 6px;
      border-left: 2px solid #710201;
      border-right: 2px solid #710201;
      position: relative;
      z-index: 2;
    }
  }

  .card-name {
    text-align: center;
    font: 400 24px/1.2 "Intro-Bold", sans-serif;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .card-cost {
    font: 400 30px/1.2 "Intro-Bold", sans-serif;
    color: #FFF;
    white-space: nowrap;
    letter-spacing: .5px;

    &.blue {
      color: #B5C7F4;
    }
  }

  .no-unit, .ok-unit {
    position: relative;
    display: block;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    -webkit-pointer-events: none;
    pointer-events: none;
    margin: 0 auto;
  }

  .no-unit {
    background: #900702;
  }

  .ok-unit {
    background: #009F6A;
  }

  .no-unit:before, .ok-unit:before {
    content: '';
    display: block;
    width: 90%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    box-sizing: border-box;
  }

  .no-unit:before {
    background: url(../images/zilant/close_icon.svg) no-repeat 50% 50%;
    background-size: auto auto;
  }

  .ok-unit:before {
    background: url(../images/zilant/tick_icon.svg) no-repeat 50% 50%;
    background-size: auto auto;
  }

  .club-table {
    td, th {
      width: 26%;
    }

    td:first-child, th:first-child {
      width: 22%;
    }
  }

  .packages-box > .packages-item {
    width: 26%;

    &:first-child {
      width: 22%;
    }
  }

  .packages-wrap {
    margin-top: 50px;
  }

  .packages-box {
    color: #FFF;
    width: 100%;
    display: flex;
    display: -ms-flexbox;
    flex-direction: row;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: stretch;
    align-items: stretch;
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    position: relative;
    z-index: 2;

    > .packages-item {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 56px;
      padding-top: 62px;
      text-align: center;

      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;
        text-align: left;
      }

      &:nth-child(n+2) {
        border-left: 1px solid rgba(57, 55, 73, 0.5);
      }
    }
  }

  .packages-item {
    .club-title {
      letter-spacing: 4px;
    }

    > {
      p {
        margin: 0;
      }

      *:nth-child(n+2) {
        margin-top: 22px;
      }

      .title {
        font: 400 24px/1.2 "Intro-Bold", sans-serif;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .time {
        font: 400 18px/1.2 "Roboto", sans-serif;
        color: #B5C7F4;
        letter-spacing: .5px;
      }

      .cost {
        font: 700 30px/1.2 "Roboto", sans-serif;
        color: #FFF;
        white-space: nowrap;
        letter-spacing: .5px;
      }
    }
  }

  .gallery-block {
    background: #0F0D18;
    padding: 35px 0 64px;
    position: relative;
    margin-top: -100px;

    .title-block {
      text-align: center;
      font: 400 38px/1.2 "Intro-Bold", sans-serif;
      color: #FFF;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  .btn-user-2 {
    min-width: 215px;
    border-radius: 300px;
    line-height: 54px;
    text-align: center;
    border: 2px solid #B90626;
    color: #B90626;
    padding: 0 32px;
    font-size: 0;
    font-weight: normal;
    transition: all .2s linear;
    box-shadow: 0 0 10px 0 #B90626, 0 0 10px 0 #B90626 inset;
    display: inline-block;
    vertical-align: middle;

    .btn-text {
      display: inline-block;
      vertical-align: middle;
      font: 18px/18px "Intro-Bold", sans-serif;
      text-transform: uppercase;
      -webkit-transform: translateY(1px);
      transform: translateY(1px);
    }

    &:hover {
      color: #FF4565;
      border-color: #FF4565;
      box-shadow: 0 0 10px 0 #FF4565, 0 0 10px 0 #FF4565 inset;
    }
  }

  .gallery-box {
    .gallery-item {
      padding: 15px !important;
    }

    margin: 0 -15px;
  }

  .gallery-unit {
    width: 100%;
    position: relative;
    font-size: 0;
    font-weight: normal;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 0;
      padding-top: 85%;
    }

    > {
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: 50% 50%;
      }

      a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .gallery-block .btn-wrap {
    margin-top: 14px;
  }

  * {
    &[data-gallery="hide"] .gallery-item {
      display: block;

      &:nth-child(n+7) {
        display: none;
      }
    }

    &[data-gallery="view-part"] .gallery-item {
      &:nth-child(n) {
        display: block;
      }

      &:nth-child(n+13) {
        display: none;
      }
    }

    &[data-gallery="view-all"] .gallery-item:nth-child(n) {
      display: block;
    }

    &[data-gallery="hide"] .btn-gallery-view .btn-text:before {
      content: attr(data-text-1);
    }

    &[data-gallery="view-part"] .btn-gallery-view .btn-text:before {
      content: attr(data-text-2);
    }

    &[data-gallery="view-all"] .btn-gallery-view .btn-text:before {
      content: attr(data-text-3);
    }
  }

  .zoom-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(2, 2, 3, 0.75);
    opacity: 0;
    transition: all .2s linear;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 60px;
      height: 60px;
      box-sizing: border-box;
      background: url(../images/zilant/zoom.svg) no-repeat 50% 50%;
      background-size: contain;
    }

    &:hover {
      opacity: 1;
    }
  }

  .club-title-2 {
    position: relative;
    z-index: 2;
    margin-bottom: 25px;
    display: none;
  }

  .club-table .btn-user .btn-text {
    font-family: "Intro-Bold", sans-serif;
    text-transform: uppercase;
  }

  .cards-wrap-mobile .club-title {
    text-align: center;
    display: block !important;
    position: relative;
    z-index: 1;

    &:after {
      display: none;
    }
  }

  .club-unit {
    width: 100%;
    padding: 28px 10px 34px;
  }

  .card-logo-box {
    display: block;
    width: 100%;
  }

  .club-unit .card-logo {
    margin: 0 auto 20px;
  }

  .table_card_ch {
    width: 100%;
    margin: 20px auto 0;
    max-width: 320px;

    td {
      vertical-align: middle;

      p {
        margin: 0;
      }

      &:first-child {
        font: 300 16px/1.2 "Roboto", sans-serif;
        color: #B5C7F4;
        text-align: left;
      }

      &:last-child {
        text-align: right;
        padding-left: 5px;
      }
    }

    tr:nth-child(n+2) td {
      padding-top: 12px;
    }
  }

  .club-unit {
    .btn-wrap {
      margin-top: 25px;
      text-align: center;
    }

    .btn-user {
      min-width: 140px;
      border-color: #E60500;

      .btn-text {
        font-family: "Intro-Bold", sans-serif;
        text-transform: uppercase;
      }
    }

    .ok-unit, .no-unit {
      display: inline-block;
      vertical-align: middle;
    }

    + .club-unit {
      border-top: 1px solid rgba(57, 55, 73, 0.5);
    }
  }

  .cards-wrap-desktop {
    display: block;
  }

  .cards-wrap-mobile {
    display: none;
  }

  @media (max-width: 1440px) {
    .zone-item-text {
      > {
        .title {
          font-size: 38px;
          padding-left: 76px;
          margin-left: -76px;
        }

        ul li {
          font-size: 18px;
        }

        .title:before {
          width: 58px;
          height: 4px;
          top: 22px;
        }
      }

      padding-left: 76px;
    }
    .zone-item {
      padding: 180px 25px 120px !important;
      min-height: 500px;
    }
  }

  @media (max-width: 1200px) {
    .pc-characteristics-wrap .dash-title {
      font-size: 38px;

      &::before {
        top: 20px;
      }
    }
  }

  @media (max-width: 1100px) {
    .pc-characteristics-cover {
      width: 580px;
      margin-left: -160px;
    }
    .club-block {
      padding: 80px 0 100px;
    }
    .page-video-box {
      max-width: 600px;

      > .title {
        font-size: 25px;
      }
    }
    .preference-unit {
      .text {
        font-size: 19px;
      }

      .text-num .num {
        font-size: 50px;
        line-height: 50px;
      }
    }
  }

  @media (max-width: 1000px) {
    .club-table .td-title {
      font-size: 16px;
    }
    .club-title-2 {
      display: block;
      text-align: left;
    }
    .club-title-1 {
      display: none;
    }
    .club-table-wrap {
      padding: 0 25px 0;
    }
    .cards-wrap {
      padding-top: 40px;
    }
    .table-blue-lights-1:before {
      box-shadow: 0 0 93px 56px rgb(0, 75, 235);
    }
    .card-cost {
      font-size: 24px;
    }
    .card-logo {
      max-width: 98px;
    }
    .club-table .btn-user {
      min-width: 120px;
    }
  }

  @media (max-width: 991px) {
    .zone-item-text {
      max-width: 100%;

      > {
        .title {
          font-size: 48px;
          padding-left: 102px;
          margin-left: -102px;
        }

        ul li {
          font-size: 22px;

          &:nth-child(n+2) {
            margin-top: 22px;
          }
        }

        .title:before {
          top: 27px;
          width: 87px;
          height: 5px;
        }
      }

      padding-left: 126px;
    }
    .zones-block .zones-box {
      margin: 0 0;
    }
    .zone-item {
      padding: 50px 25px 30px !important;
      min-height: 430px;
    }
    .zone-item-text > ul li {
      &:nth-child(n+2) {
        margin-top: 17px;
      }

      br {
        display: none;
      }
    }
  }

  @media (max-width: 980px) {
    .ch-unit > .content {
      min-height: 100px;
    }
  }

  @media (max-width: 900px) {
    .pc-characteristics-cover {
      width: 470px;
      margin-left: -140px;
    }
    .ch-unit > .content {
      .name, .category {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 800px) {
    .page-video-box {
      > .title {
        font-size: 18px;
      }

      max-width: 364px;
    }
    .preference-block {
      padding-top: 0;
    }
    .preference-unit {
      .text-num .num {
        font-size: 36px;
        line-height: 36px;
        min-width: 22px;
      }

      .text {
        font-size: 16px;
        padding-top: 27px;
        padding-left: 0;
      }

      .text-num .icon {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
      }
    }
    .preference-box {
      margin-top: 60px;
    }
    .pc-characteristics-wrap {
      margin-top: 82px;
    }
    .pc-characteristics-cover {
      width: 444px;
      margin-left: -109px;
    }
    .dash-title {
      font-size: 36px;
      padding-left: 88px;
      margin-left: -88px;

      &::before {
        top: 22px;
      }
    }
    .pc-characteristics-content {
      padding-top: 0;
      padding-left: 30px;

      .dash-title {
        margin-bottom: 14px;
      }
    }
    .ch-unit {
      > {
        .content {
          .category, .name {
            font-size: 16px;
          }
        }

        .icon {
          width: 43px;
        }
      }

      &:nth-child(n+2) {
        margin-top: 18px;
      }

      min-height: 78px;
    }
    .packages-item .club-title {
      letter-spacing: 1px;
    }
    .gallery-block {
      padding-top: 60px;
      margin-top: 70px;
    }
    .gallery-block {
      padding-bottom: 40px;

      .btn-wrap {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 740px) {
    .pc-characteristics-wrap .dash-title {
      font-size: 30px;

      &:before {
        top: 15px;
      }
    }
  }

  @media (max-width: 723px) {
    .pc-characteristics-cover {
      width: 275px;
      margin-left: -39px;
    }
  }

  @media (max-width: 700px) {
    .cards-wrap-desktop {
      display: none;
    }
    .cards-wrap-mobile {
      display: block;
    }
    .packages-box {
      flex-direction: column;

      > .packages-item {
        &:nth-child(n) {
          width: 100%;
          padding: 0 8px 20px;
          border: none;
          text-align: center;
        }

        &:nth-child(n+2) {
          padding-top: 20px;
          border-top: 1px solid rgba(57, 55, 73, 0.5);
        }

        &:last-child {
          padding-bottom: 30px;
        }

        &:first-child {
          padding-top: 45px;
          padding-bottom: 40px;
        }
      }
    }
    .packages-item .club-title {
      text-align: center;
      padding: 0;
      margin: 0;
      font-size: 30px;
    }
    .packages-box > .packages-item:nth-child(2) {
      border-top: none;
      padding-top: 0;
    }
    .packages-item .club-title:after {
      display: none;
    }
    .packages-wrap {
      margin-top: 28px;
    }
    .gallery-block .title-block {
      font-size: 30px;
    }
    .gallery-box {
      .gallery-item {
        padding: 10px !important;
      }

      margin: 0 -10px;
    }
  }

  @media (max-width: 600px) {
    .zone-item-text {
      > {
        ul li {
          font-size: 16px;
        }

        .title {
          font-size: 30px;
          padding-left: 54px;
          margin-left: -54px;

          &::before {
            width: 28px;
            top: 15px;
          }
        }
      }

      padding-left: 54px;
    }
    .zone-item {
      min-height: 292px;
    }
    .btn-user-2 {
      padding: 0 8px;

      .btn-text {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  @media (max-width: 575px) {
    .ch-unit {
      &:nth-child(n+2) {
        margin-top: 20px;
      }

      min-height: 50px;
    }
    .pc-ch-box .col-12:nth-child(n+2) {
      margin-top: 16px !important;
    }
    .pc-characteristics-wrap {
      display: block;
      margin-top: 40px;
    }
    .pc-characteristics-cover {
      margin: 0;
      max-width: 100%;
      width: 640px;
      margin-left: -54px;
      margin-right: -16px;
    }
    .pc-characteristics-content {
      padding: 40px 0 0;
    }
    .dash-title {
      padding-left: 0;
      margin-left: 0;

      &:before {
        display: none;
      }
    }
    .pc-characteristics-content .dash-title {
      bottom: 20px;
    }

    .preference-unit {
      display: block;

      .text-num {
        display: inline-block;
        vertical-align: top;
      }

      .text {
        display: block;
        width: 100%;
        padding-top: 5px;
      }

      .text-num {
        .num {
          padding-top: 30px;
        }

        .icon {
          margin-left: -20px;
        }
      }
    }
    .ch-unit > .content {
      min-height: 0;
    }
  }

  @media (max-width: 500px) {
    .page-video-box > .title {
      font-size: 16px;
    }
    .btn-play {
      width: 72px;
    }
    .pc-characteristics-wrap .dash-title {
      font-size: 30px;
    }
    .gallery-block {
      margin-top: 40px;
    }
    .gallery-box {
      .gallery-item {
        padding: 5px !important;
      }

      margin: 0 -5px;
    }
  }

  @media (max-width: 400px) {
    .preference-item:nth-child(2n) {
      margin-left: -12px !important;
    }
    .pc-characteristics-cover {
      max-width: 120%;
      margin-left: -50px;
      margin-right: -16px;
    }
    .zone-item-text {
      padding-left: 28px;
    }
    .club-table-wrap {
      padding: 0 6px;
    }
  }
}